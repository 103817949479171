<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Color Families
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        outlined
        dense
        v-model="filter.query"
        placeholder="Search..."
        append-icon="fas fa-search"
        single-line
        hide-details
        @keyup="searchKeyUp()"
      ></v-text-field>
      <v-btn
        class="ml-4"
        depressed
        color="secondary"
        @click="newClick"
        :disabled="!user.can('color-family.create')"
      >
    <v-icon
      small
      left
    >fas fa-folder-plus</v-icon>
    New
    </v-btn>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
      :loading="isSearching"
      @click:row="rowClick"
    >
    
      <template v-slot:item.is_active="{ item }">
        <v-icon :color="item.is_active ? 'secondary' : 'grey'" x-small>fas fa-circle</v-icon>
      </template>

    </v-data-table>


    <v-dialog
      v-model="dialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="primary" dark flat short class="px-2">
          <v-toolbar-title>Family</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <v-form @submit.prevent>
            <v-text-field
              outlined
              class="mb-2"
              v-model="model.code"
              :error-messages="errors.code"
              label="Code"
            ></v-text-field>

            <v-text-field
              outlined
              class="mb-2"
              v-model="model.name"
              :error-messages="errors.name"
              label="Name"
            ></v-text-field>

          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="dialog = false"
          >Cancel</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="saveClick()"
          >Save</v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>

  </v-card>
  
</template>

<script>
import { inject, ref } from 'vue'
import { useRouter } from 'vue-router/composables'

export default {
  setup() {
    const api = inject('api')
    const loader = inject('loader')
    const router = useRouter()

    const itemCollection = api.collection('color-families', {
      params: {
      },
      filter: {
        query: 'query:code,name',
      },
      store: true,
    })

    const { items, filter, totalCount, options, isSearching } = itemCollection

    const headers = [
      { text: 'Code', value: 'code', },
      { text: 'Name', value: 'name' },
      { text: 'Active', value: 'is_active', align: 'center' },
    ]

    const dialog = ref(false)

    const itemModel = api.model('color-families/:id', {
      params: {
      },
    })
    const { attributes: model, errors, isBusy } = itemModel

    loader.bindRef(isBusy)

    function newClick() {
      itemModel.clear()
      dialog.value = true
    }

    function rowClick(item) {
      router.push({
        name: 'color',
        params: { id: item.id }
      })
    }

    function saveClick() {
      itemModel.save().then(() => {
        itemCollection.search()
        router.push({
          name: 'color',
          params: { id: itemModel.getAttribute('id') }
        })
        dialog.value = false
      })
    }

    const searchKeyUp = itemCollection.debounceSearch(300)

    return {
      user: api.user,
      headers,
      items,
      filter,
      searchKeyUp,
      totalCount,
      options,
      isSearching,
      newClick,
      rowClick,
      saveClick,
      dialog,
      model,
      errors,
    }

  },
}
</script>


<style scoped>

:deep(.v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover) {
  cursor: pointer;
}

</style>
